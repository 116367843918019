import { useTranslation } from '@loveholidays/phrasebook';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';

import { formatCalendarDate, formatDate, utcDate } from './dates';
import { localizedFormat } from './locale';
import { useAppContext } from '@Client/context/contexts';
import { getDayGridInWeeks } from '@Dates/calendar';
import { formatMonthYear, fullYearMonthsFromNow } from '@Dates/months';
import { formatLocalTime } from '@Dates/time';
import { getWeekdaysMin } from '@Dates/weeks';

export const useDate = () => {
  const { site } = useAppContext();
  const { t } = useTranslation();

  const locale = site.dayjsLocale;

  return useMemo(
    () => ({
      // dates
      formatCalendarDate: (date: string | number | Dayjs) =>
        formatCalendarDate(locale, date, t('calendarDateFormat')),
      formatDate: (date: string, inputFormat: string, outputFormat: string) =>
        formatDate(locale, date, inputFormat, outputFormat),
      utcDate,
      now: dayjs.utc(),

      // weeks
      getWeekdaysMin: () => getWeekdaysMin(locale),
      getDayGridInWeeks: (yearMonth: string) => getDayGridInWeeks(locale, yearMonth),

      // months
      formatMonthYear: (yearMonth: string) => formatMonthYear(locale, yearMonth),
      formatAbbreviatedMonthYear: (date: Dayjs) => date.format('MMM YYYY'),
      fullYearMonthsFromNow: (): string[] => fullYearMonthsFromNow(locale),

      // time
      formatLocalTime: (time: string) => formatLocalTime(locale, time),
      localizedFormat: (date: Dayjs | string, format?: string, convertToUtc?: boolean) =>
        localizedFormat(locale, date, format || t('localisedDateFormat'), convertToUtc),
      formatDuration: (durationInMinutes: number) => {
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;

        return t('duration', {
          hours,
          minutes,
          context: `${hours ? 'h' : ''}${minutes ? 'm' : ''}`,
        });
      },
      getDaysDifference: (from: Dayjs, to: Dayjs) => dayjs.duration(to.diff(from)).days(),
    }),
    [locale, t],
  );
};
